<template>
    <Breadcrumb page-title="Logged In Chat Agents" page-section="Pages"> </Breadcrumb>
     <section class="content">
        <p>This page shows all agents with chat rights that are currently logged into iService.</p>
        <div class="box box-info">
            <div class="box-header with-border"  v-on:click="toggleSegmentCollapsedState('1')">
                <span class="fa-stack new-account-icon"><i class="fa fa-user"></i><i class="fa fa-commenting icon-plus"></i></span>
                <h3 class="box-title head-title-padding">Logged In Agents</h3>
                <span aria-hidden="true" class="fa fa-angle-right pull-right expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment1}"></span>
            </div>
            <div class="box-body table-responsive" v-bind:class = "segment1?'in collapse':''">
                <div class="notdatatable form-inline mbottom-15">
                    <div class="table-controls table-columns">
                        <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                    </div>
                    <div class="table-controls table-pagesize table-columns">
                        <label>
                            Show 
                            <select class="form-control input-xs">
                                <option>5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>250</option>
                                <option>500</option>
                                <option>1000</option>
                            </select>
                            entries 
                        </label>
                    </div>
                   
                    <div class="table-controls table-filter">Filter Agents <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                </div>
                
                <table class="table table-striped table-hover">
                    <thead>
                        <tr role="row">
                           
                            <th class="sorting">
                                Available
                            </th>
                            <th class="sorting">
                                Agent Name
                            </th>
                            <th class="sorting">
                                Assigned
                            </th>
                            <th class="sorting sorting_asc">
                                Completed Today
                            </th>
                            <th class="sorting sorting_desc">
                                Today's Activity: <br>
                                <span class="graph-assigned-block"><span class="graph-assigned bg-aqua"></span> &nbsp; Assigned </span>
                                <span class="graph-assigned-block"><span class="graph-assigned bg-orange"></span>&nbsp;  Completed </span>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr role="row">
                            <td class="sorting_1">
                                <a href="#"><i class="fa fa-circle text-success"></i></a>
                            </td>
                            <td>
                                Jacob Smith  
                            </td>
                            <td>
                                30
                            </td>
                            <td>
                              20
                            </td>
                            <td>
                                        <span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span>
                                    <span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span>
                                
                                </td>
                          
                        </tr>
                          
                            <tr role="row">
                                    <td class="sorting_1">
                                        <a href="#"><i class="fa fa-circle text-danger"></i></a>
                                    </td>
                                    <td>
                                        Alek Packard 
                                    </td>
                                    <td>
                                        5
                                    </td>
                                    <td>
                                        10
                                    </td>
                                    <td> <span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-aqua"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span><span class="graph-assigned bg-orange"></span> 
                                               
                                            </td>
                                    
                            </tr>
                    
                    </tbody>
                    </table>
                <div class="dataTables_info" id="example1_info" role="status" aria-live="polite">Showing 1 to 3 of 3 entries</div>
                <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate"><ul class="pagination"><li class="paginate_button previous disabled" id="example1_previous"><a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0">Previous</a></li><li class="paginate_button active"><a href="#" aria-controls="example1" data-dt-idx="1" tabindex="0">1</a></li><li class="paginate_button next disabled" id="example1_next"><a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0">Next</a></li></ul></div>
            </div>
        </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
   import SearchContacts from '../components/SearchContact.vue';
   
   export default {
     name: 'Logged in Agents',
     components: {
       Breadcrumb,
       SearchContacts
     },
  data: function(){
      return{
        segment1 : false,
        segment2 : false,
        segment3 : false,
        segment4 : false
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>